import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
//
import { Layout, SEO } from '../components';
import { Motorcycles, EBikes } from '../components/HomeBikes';
import HomeSlider from '../components/Sliders/HomeSlider';
import PartsDepartment from '../components/PartsDepartment';
import CustomerReviews from '../components/CustomersReviews';
import RepairShopParalax from '../components/RepairShopParalax';
import { EBikesFeature } from '../features/e-bikes';
import { Popup } from '../components/popup';

const IndexPage = () => (
  <Layout>
    <IndexInner />
  </Layout>
);

const IndexInner = () => {
  const data = useStaticQuery(graphql`
    query SingleMotorcycle {
      allSanityMotorcycle(filter: { isOnSalePending: { ne: true }, isSold: { ne: true }, featured: { eq: true } }) {
        edges {
          node {
            title
            price
            mileage
            engine
            year
            hasLogo
            slug {
              current
            }
            featuredImage {
              image {
                asset {
                  fluid(maxWidth: 300) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
      sanityHomeMetadata {
        metadata {
          title
          keywords
          description
          image {
            asset {
              url
            }
          }
        }
      }
      allSanityEbikes(filter: { isOnSalePending: { ne: true }, isSold: { ne: true }, featured: { eq: true } }) {
        edges {
          node {
            title
            price
            motor
            maxSpeed
            maxRange
            hasLogo
            slug {
              current
            }
            featuredImage {
              image {
                asset {
                  fluid(maxWidth: 300) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { metadata } = data.sanityHomeMetadata;
  const availableEbikes = data.allSanityEbikes.edges || [];
  const availableMotorcycles = data.allSanityMotorcycle.edges || [];

  return (
    <>
      <SEO path="/" {...metadata} />
      <HomeSlider />
      <EBikesFeature>{availableEbikes.length ? <EBikes availableEbikes={availableEbikes} /> : null}</EBikesFeature>
      <Motorcycles availableMotorcycles={availableMotorcycles} />
      <RepairShopParalax />
      <PartsDepartment />
      <CustomerReviews />
      <Popup />
    </>
  );
};

export default IndexPage;
