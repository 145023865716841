import React, { useState } from 'react';
import './index.scss';

export const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const className = isOpen ? '' : 'hide';

  return (
    <div className={`modal-overlay ${className}`}>
      <div className="modal">
        <div className="modal-header">
          <button type="button" className="close-button" onClick={() => setIsOpen(false)}>
            &times;
          </button>
        </div>

        <div className="modal-content">
          <h5>Due to our Summer vacation, we will be closed from Saturday, 08.10.2024. - Monday, 09.02.2024.</h5>
          <h5>Returning to normal hours of operation on Tuesday, 09.02.2024.</h5>
          <h5>We apologize for any inconvenience and look forward to service your motorcycle needs.</h5>
          <h5 className="jon">Thank you, <br /> JP Superbikes</h5>
        </div>
      </div>
    </div>
  );
};
